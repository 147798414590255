import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class PreRegistrationSettingService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/program/`;

    }


    // pre-registration
    paginate(program, type = null) {
        let url = `${this.#api}${program}/pre-registration`;
        if (type)
            url = url + '?type=' + type;
        return apiService.get(url);
    }

    update(program, id, data) {
        let url = `${this.#api}${program}/pre-registration/${id}/update`
        return apiService.post(url, data);

    }

    store(program, data) {
        let url = `${this.#api}${program}/pre-registration`
        return apiService.post(url, data);

    }

    show(program, id) {
        let url = `${this.#api}${program}/pre-registration/${id}`
        return apiService.get(url);
    }

    delete(program, id) {
        let url = `${this.#api}${program}/pre-registration/${id}`
        return apiService.delete(url);
    }

    allActive() {
        let url = `${this.#api}pre-registration/active/all`
        return apiService.get(url);
    }
    getAllCurrentSetting() {
        let url = `${this.#api}pre-registration/get-all-settings/current-year`
        return apiService.get(url);
    }
    cloneSetting(settingId, academicId) {
        let url = `${this.#api}pre-registration/${settingId}/academic-year/${academicId}/clone`
        return apiService.get(url);
    }

    getOpenedAdmission() {
        let url = `${API_URL}user/opened/pre-registration-program`;
        return apiService.get(url);
    }

    getPreregistrationProgramsByLevel(level) {
        let url = `${API_URL}user/pre-registration-program/${level}/level`
        return apiService.get(url);
    }

    preRegistrationByProgram(programId) {
        let url = `${API_URL}user/pre-registration/${programId}/detail`
        return apiService.get(url);
    }


}
